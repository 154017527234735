import { template as template_c948a7eb013f4324899b28a0dc1e49d5 } from "@ember/template-compiler";
const FKText = template_c948a7eb013f4324899b28a0dc1e49d5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
