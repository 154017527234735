import { template as template_cf8af48f8c4a4f52a232e24eafc7440c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_cf8af48f8c4a4f52a232e24eafc7440c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
