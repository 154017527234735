import { template as template_ac22fa3e88df4ffea1e3184176cb5d17 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ac22fa3e88df4ffea1e3184176cb5d17(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
