import { template as template_daeaaf0f5c6d45c18c6adc73fa594ce6 } from "@ember/template-compiler";
const FKLabel = template_daeaaf0f5c6d45c18c6adc73fa594ce6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
